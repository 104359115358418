function SportCard({ sport }) {
    return (
        <div className="rounded-lg flex justify-start w-full bg-custom-gradient">
            <div className="m-2.5 rounded-lg ">
                <img src={sport.img} alt='' className="w-full h-full object-cover"/>
            </div>
            <div className="py-8">
                <p className="text-size-2_8 pb-1.5 text-white">{sport.name}</p>
                <p className="text-2xl text-light">Today {sport.title}: {sport.games}</p>
            </div>
        </div>
    );
}

export default SportCard;
