function CategoryCard({ category }) {
    return (
        <div className="flex flex-col rounded-lg overflow-hidden bg-custom-gradient">
            <div>
                <img src={category.image} alt={category.title} className="w-full h-full object-cover"/>
            </div>
            <div className="flex-grow">
            <p className="pt-8 text-center font-bold text-white text-3xl">
                    {category.title}
                </p>
                <p className="pt-4 pb-spacing_30 text-light px-spacing_30 leading-size-2_2 text-size-2_2 text-center">
                    {category.description}
                </p>
            </div>
        </div>
    );
}

export default CategoryCard;
