import HeaderCard from "./сards/HeaderCard";
import MemeList from "./MemeList";
import DragToScroll from "./hooks/useDragScroll";

function MemeCollections({ newsData }) {
    const limitedMemeData = newsData.slice(0, 8);
    return (
        <div className="px-spacing_100 font-poppins">
            <HeaderCard
                title="Meme Collections"
                buttonText="See all"
                titleClass="text-white"
                buttonClass="text-light"
                containerClass="pt-20"
                reverseOrder={true}
            />
            <div className="overflow-hidden pt-spacing_50 pb-spacing_100">
                <DragToScroll>
                    <MemeList memes={limitedMemeData} />
                </DragToScroll>
            </div>
        </div>
    );
}

export default MemeCollections;
