import image1 from '../assets/images/CategoriOne.png';
import image2 from '../assets/images/CategoriTwo.png';
import image3 from '../assets/images/CategoriThree.png';
import image4 from '../assets/images/CategoriFour.png';

const CategoriesData = [
  {
    title: "Sport",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
    image: image1,
  },
  {
    title: "Crypto",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing hjght",
    image: image2,
  },
  {
    title: "Mems",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing hjght",
    image: image3,
  },
  {
    title: "Trading",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing hjght",
    image: image4,
  },
  {
    title: "Integer nec odio",
    description:
      "Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.",
  },

];

export default CategoriesData;
