import HeaderCard from "./сards/HeaderCard";
import SportCard from "./сards/SportCard";

function Sport({ sportsData }) {
    return (
        <div className="font-poppins">
            <div className="px-spacing_100">
                <HeaderCard
                    title="Sport"
                    buttonText="See all"
                    titleClass="text-white"
                    buttonClass="text-light"
                    containerClass="pt-20"
                    reverseOrder={true}
                />
                <div className="pt-spacing_50 pb-spacing_100 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 gap-y-gap-lg">
                    {sportsData.slice(0, 4).map((sport, index) => (
                        <SportCard key={index} sport={sport} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Sport;
