function LocationIcon() {
    return (
        <svg width="18" height="22" viewBox="0 0 18 22" fill="FEFEFE" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.247 19.969C10.3971 18.9254 11.4615 17.7911 12.43 16.577C14.47 14.014 15.711 11.487 15.795 9.24C15.8282 8.32679 15.6771 7.41626 15.3505 6.56278C15.024 5.7093 14.5288 4.9304 13.8945 4.27259C13.2602 3.61478 12.4998 3.09157 11.6588 2.7342C10.8177 2.37684 9.91331 2.19265 8.9995 2.19265C8.08569 2.19265 7.18125 2.37684 6.34022 2.7342C5.49918 3.09157 4.73881 3.61478 4.10451 4.27259C3.4702 4.9304 2.975 5.7093 2.64846 6.56278C2.32192 7.41626 2.17076 8.32679 2.204 9.24C2.289 11.487 3.531 14.014 5.57 16.577C6.53846 17.7911 7.60289 18.9254 8.753 19.969C8.86367 20.069 8.946 20.1417 9 20.187L9.247 19.969ZM8.262 21.134C8.262 21.134 1 15.018 1 9C1 6.87827 1.84285 4.84344 3.34315 3.34315C4.84344 1.84285 6.87827 1 9 1C11.1217 1 13.1566 1.84285 14.6569 3.34315C16.1571 4.84344 17 6.87827 17 9C17 15.018 9.738 21.134 9.738 21.134C9.334 21.506 8.669 21.502 8.262 21.134ZM9 11.8C9.74261 11.8 10.4548 11.505 10.9799 10.9799C11.505 10.4548 11.8 9.74261 11.8 9C11.8 8.25739 11.505 7.5452 10.9799 7.0201C10.4548 6.495 9.74261 6.2 9 6.2C8.25739 6.2 7.5452 6.495 7.0201 7.0201C6.495 7.5452 6.2 8.25739 6.2 9C6.2 9.74261 6.495 10.4548 7.0201 10.9799C7.5452 11.505 8.25739 11.8 9 11.8ZM9 13C7.93913 13 6.92172 12.5786 6.17157 11.8284C5.42143 11.0783 5 10.0609 5 9C5 7.93913 5.42143 6.92172 6.17157 6.17157C6.92172 5.42143 7.93913 5 9 5C10.0609 5 11.0783 5.42143 11.8284 6.17157C12.5786 6.92172 13 7.93913 13 9C13 10.0609 12.5786 11.0783 11.8284 11.8284C11.0783 12.5786 10.0609 13 9 13Z"
                  fill="#FEFEFE"/>
            <path
                d="M9.065 20.243L9.059 20.238M9.247 19.969C10.3971 18.9254 11.4615 17.7911 12.43 16.577C14.47 14.014 15.711 11.487 15.795 9.24C15.8282 8.32679 15.6771 7.41626 15.3505 6.56278C15.024 5.7093 14.5288 4.9304 13.8945 4.27259C13.2602 3.61478 12.4998 3.09157 11.6588 2.7342C10.8177 2.37684 9.91331 2.19265 8.9995 2.19265C8.08569 2.19265 7.18125 2.37684 6.34022 2.7342C5.49918 3.09157 4.73881 3.61478 4.10451 4.27259C3.4702 4.9304 2.975 5.7093 2.64846 6.56278C2.32192 7.41626 2.17076 8.32679 2.204 9.24C2.289 11.487 3.531 14.014 5.57 16.577C6.53846 17.7911 7.60289 18.9254 8.753 19.969C8.86367 20.069 8.946 20.1417 9 20.187L9.247 19.969ZM8.262 21.134C8.262 21.134 1 15.018 1 9C1 6.87827 1.84285 4.84344 3.34315 3.34315C4.84344 1.84285 6.87827 1 9 1C11.1217 1 13.1566 1.84285 14.6569 3.34315C16.1571 4.84344 17 6.87827 17 9C17 15.018 9.738 21.134 9.738 21.134C9.334 21.506 8.669 21.502 8.262 21.134ZM9 11.8C9.74261 11.8 10.4548 11.505 10.9799 10.9799C11.505 10.4548 11.8 9.74261 11.8 9C11.8 8.25739 11.505 7.5452 10.9799 7.0201C10.4548 6.495 9.74261 6.2 9 6.2C8.25739 6.2 7.5452 6.495 7.0201 7.0201C6.495 7.5452 6.2 8.25739 6.2 9C6.2 9.74261 6.495 10.4548 7.0201 10.9799C7.5452 11.505 8.25739 11.8 9 11.8ZM9 13C7.93913 13 6.92172 12.5786 6.17157 11.8284C5.42143 11.0783 5 10.0609 5 9C5 7.93913 5.42143 6.92172 6.17157 6.17157C6.92172 5.42143 7.93913 5 9 5C10.0609 5 11.0783 5.42143 11.8284 6.17157C12.5786 6.92172 13 7.93913 13 9C13 10.0609 12.5786 11.0783 11.8284 11.8284C11.0783 12.5786 10.0609 13 9 13Z"
                stroke="#FEFEFE" strokeWidth="0.5"/>
        </svg>

    )
}

export default LocationIcon