function SportInfo() {

  return (
      <div className="w-full py-spacing_50 px-spacing_100 font-poppins">
        <div className="flex flex-col items-start justify-center rounded-lg text-left text-white pl-spacing_90">
          <div className="flex flex-col items-start justify-start text-left max-w-5xl py-28">
            <h1 className="text-size-5_8 leading-size-5_8 font-bold">
              1 LOREM <span className='text-login-button-send'> ISPUT DOLOR </span> SIT AMET, CONSECTETUR ADIPISCING
            </h1>
            <p className="text-size-2_6 pt-3.5 pb-9">
              1 LOREM ISPUT DOLOR <span className='text-login-button-send'>SIT AMET</span>, CONSECTETUR ADIPISCING
            </p>
            <button className="py-spacing_14 px-8 bg-login-button-send text-2xl text-white rounded-lg">
              See More
            </button>
          </div>
        </div>
      </div>
  );
}

export default SportInfo;
