const coursesData = [
  {
    title: "What is SMM?",
    date: "April 25,2024",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    img: require("../assets/images/Images8.jpg"),
  },
  {
    title: "Trading Basics?",
    date: "May 13,2024",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    img: require("../assets/images/CategoriFour.png"),
  },
  {
    title: "Strategies",
    date: "Jun 25,2024",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    img: require("../assets/images/Images4.jpg"),
  },
];

export default coursesData;
