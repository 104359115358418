import FaceBookIcon from '../icons/FaceBookIcon';
import InstagramIcon from "../icons/InstagramIcon";
import TwitterIcon from "../icons/TwitterIcon";

function SocialMedia() {
    return (
        <div className="space-y-[16px] text-white">
            <p className="text-lg font-bold">Follow us in</p>
            <div className="flex space-x-[16px]">
                <FaceBookIcon />
                <InstagramIcon />
                <TwitterIcon />
            </div>
        </div>
    );
}


export default SocialMedia;