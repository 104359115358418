function EmailIcon() {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.0265 2.0424L18.1743 1.95H18H2H1.82566L1.9735 2.0424L9.9735 7.0424L10 7.05896L10.0265 7.0424L18.0265 2.0424ZM18 14.05H18.05V14V4V3.90979L17.9735 3.9576L10 8.94104L2.0265 3.9576L1.95 3.90979V4V14V14.05H2H18ZM18 0.05C19.0724 0.05 19.95 0.927614 19.95 2V14C19.95 15.0724 19.0724 15.95 18 15.95H2C0.927614 15.95 0.05 15.0724 0.05 14V2C0.05 0.927614 0.927614 0.05 2 0.05H18Z"
                fill="#FEFEFE" stroke="#FEFEFE" strokeWidth="0.1"/>
        </svg>
    )
}

export default EmailIcon