function Play() {
    return (
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21 41.25C23.6593 41.25 26.2925 40.7262 28.7493 39.7086C31.2062 38.6909 33.4385 37.1993 35.3189 35.3189C37.1993 33.4385 38.6909 31.2062 39.7086 28.7493C40.7262 26.2925 41.25 23.6593 41.25 21C41.25 18.3407 40.7262 15.7075 39.7086 13.2507C38.6909 10.7938 37.1993 8.56147 35.3189 6.68109C33.4385 4.8007 31.2062 3.3091 28.7493 2.29144C26.2925 1.27378 23.6593 0.75 21 0.75C15.6294 0.75 10.4787 2.88348 6.68109 6.68109C2.88348 10.4787 0.75 15.6294 0.75 21C0.75 26.3706 2.88348 31.5213 6.68109 35.3189C10.4787 39.1165 15.6294 41.25 21 41.25ZM18.2617 11.9775L30.9608 19.0335C31.3113 19.2284 31.6034 19.5135 31.8068 19.8593C32.0102 20.205 32.1174 20.5989 32.1174 21C32.1174 21.4011 32.0102 21.795 31.8068 22.1407C31.6034 22.4865 31.3113 22.7716 30.9608 22.9665L18.2617 30.0225C17.8506 30.2511 17.3868 30.3682 16.9164 30.3624C16.446 30.3565 15.9853 30.2278 15.5799 29.9891C15.1745 29.7504 14.8386 29.4099 14.6053 29.0014C14.372 28.5928 14.2496 28.1304 14.25 27.66V14.34C14.2496 13.8696 14.372 13.4072 14.6053 12.9986C14.8386 12.5901 15.1745 12.2496 15.5799 12.0109C15.9853 11.7722 16.446 11.6435 16.9164 11.6376C17.3868 11.6318 17.8506 11.7489 18.2617 11.9775Z"
                  fill="#FEFEFE"/>
        </svg>

    )
}


export default Play;