import { useRef } from "react";

function DragToScroll({ children }) {
    const scrollContainerRef = useRef(null);

    const handleMouseDown = (e) => {
        const container = scrollContainerRef.current;
        container.isDragging = true;
        container.startX = e.clientX;
        container.scrollLeftStart = container.scrollLeft;
        container.style.cursor = "grabbing";
    };

    const handleMouseMove = (e) => {
        const container = scrollContainerRef.current;
        if (!container.isDragging) return;
        const dx = e.clientX - container.startX;
        container.scrollLeft = container.scrollLeftStart - dx;
    };

    const handleMouseUp = () => {
        const container = scrollContainerRef.current;
        container.isDragging = false;
        container.style.cursor = "grab";
    };

    const handleMouseLeave = () => {
        const container = scrollContainerRef.current;
        container.isDragging = false;
        container.style.cursor = "grab";
    };

    return (
        <div
            ref={scrollContainerRef}
            className="flex cursor-grab"
            style={{
                display: "flex",
                overflow: "hidden",
                whiteSpace: "nowrap",
                userSelect: "none",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
        >
            {children}
        </div>
    );
}

export default DragToScroll;
