
function CryptoNewsCard({ news }) {
    return (
        <div className="bg-custom-gray rounded-lg flex flex-col">
            <div className="rounded-lg w-full h-[385px]">
                <img
                    src={news.image}
                    alt={news.title}
                    className="w-full h-full object-cover rounded-lg"
                />
            </div>

            <div className="py-8 px-spacing_30 flex flex-col justify-between flex-grow">
                <div>
                    <p className="pb-1.5 text-light">{news.timeAgo}</p>
                    <p className="pb-3.5 text-size-3_0 leading-size-3_0 text-white">{news.title}</p>
                    <p className="text-size-2_2 leading-size-2_2 text-light pb-spacing_66">{news.description}</p>
                </div>
                <div className="flex justify-between text-light mt-auto">
                    <div className="flex justify-between space-x-spacing_100">
                        <p>View: {news.views}</p>
                        <p>Share: {news.shares}</p>
                    </div>
                    <p>Share</p>
                </div>
            </div>
        </div>
    );
}

export default CryptoNewsCard;
