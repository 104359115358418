import mem1 from '../assets/images/Images1.jpg';
import mem2 from '../assets/images/Images2.jpg';
import mem3 from '../assets/images/Images3.jpg';
import mem4 from '../assets/images/Images4.jpg';
import mem5 from '../assets/images/Images5.jpg';
import mem6 from '../assets/images/Images6.jpg';
import mem7 from '../assets/images/Images7.jpg';
import mem8 from '../assets/images/Images8.jpg';
import mem9 from '../assets/images/Images9.jpg';


const MemeColectionsData = [
  { id: 1, title: "Meme 1", imageUrl: mem1 },
  { id: 2, title: "Meme 2", imageUrl: mem2 },
  { id: 3, title: "Meme 3", imageUrl: mem3 },
  { id: 4, title: "Meme 4", imageUrl: mem4 },
  { id: 5, title: "Meme 5", imageUrl: mem5 },
  { id: 6, title: "Meme 6", imageUrl: mem6 },
  { id: 7, title: "Meme 7", imageUrl: mem7 },
  { id: 8, title: "Meme 8", imageUrl: mem8 },
  { id: 9, title: "Meme 9", imageUrl: mem9 },
];

export default MemeColectionsData;
