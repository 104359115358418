function LegalInfo() {
    return (
        <div className="space-y-[16px] text-white">
            <p className="text-lg font-bold">Legal</p>
            <p>Privacy Policy</p>
            <p>Cookie Policy</p>
            <p>General Terms</p>
            <p>Terms & Conditions</p>
        </div>
    );
}


export default LegalInfo;