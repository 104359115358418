function AboutUs() {
  return (
      <div className="px-spacing_100 font-poppins">
        <h1 className="text-white flex justify-start text-size-3_2 font-bold pt-20">
          About Us
        </h1>

        <div className="pb-spacing_100 pt-spacing_50 flex flex-col lg:flex-row">
          <div className="rounded-lg w-full lg:w-[50%] h-[530px] mb-spacing_30 lg:mb-0 lg:mr-spacing_30">
            <img
                src={require('../assets/images/AboutUs.jpg')}
                alt="About Us"
                className="w-full h-full object-cover rounded-lg"
            />
          </div>

          <div className="w-full lg:w-[60%]">
            <h2 className="text-white text-size-3_2 pb-5">
              Who we are?
            </h2>
            <p className="text-white text-lg pb-spacing_18">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
              ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
              pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
              culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p className="text-white text-lg pb-spacing_18">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
              ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea commodo consequat.
            </p>
            <p className="text-white text-lg pb-spacing_18">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
              ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate velit
              esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </p>
          </div>
        </div>
      </div>
  );
}

export default AboutUs;
