import Play from "./icons/Play";

function VideoCourses({ coursesData }) {
    return (
        <div className="p-5 md:p-10 lg:p-spacing_100">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-gap-lg">
                <div className="col-span-1 flex flex-col justify-center">
                    <h2 className="text-white text-size-3_2 font-bold pb-1.5">
                        VIDEO COURSES
                    </h2>
                    <p className="text-base text-light">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore. Sed do eiusmod tempor incididunt
                        ut labore.
                    </p>
                </div>
                {coursesData.slice(0, 3).map((course, index) => (
                    <div key={index} className="col-span-1">
                        <div className="relative h-[280px] w-full rounded-lg overflow-hidden ">
                            <img src={course.img} alt='' className="w-full h-full object-cover" />
                            <div className="absolute inset-0 flex items-center justify-center cursor-pointer">
                                <Play />
                            </div>
                        </div>
                        <p className="pt-3 text-light text-xs">{course.date}</p>
                        <h2 className="pt-1 text-white text-size-2_0 font-bold">
                            {course.title}
                        </h2>
                        <p className="pt-2.5 text-light text-base">
                            {course.description}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default VideoCourses;
