import PhoneIcon from "..//icons/PhoneIcon";
import LocationIcon from "../icons/LocationIcon";
import EmailIcon from "../icons/EmailIcon";

function ContactInfo() {
    return (
        <div className="space-y-[16px] text-white">
            <p className="text-[50px] font-bold">LOGO</p>
            <div className="flex items-center space-x-[14px]">
                <PhoneIcon />
                <p>Call +374 44 778 887</p>
            </div>
            <div className="flex items-center space-x-[14px]">
                <LocationIcon />
                <p>Yerevan. Armenia</p>
            </div>
            <div className="flex items-center space-x-[14px]">
                <EmailIcon />
                <p>Email bitarmcrypto@mail.ru</p>
            </div>
        </div>
    );
}



export default ContactInfo;