import HeaderCard from "./сards/HeaderCard";
import CategoryCard from "./сards/CategoryCard";

function Categories({ categoriesData }) {
    return (
        <div className="font-poppins">
            <div className="px-spacing_100 pt-20">
                <HeaderCard
                    title="Categories"
                    buttonText="See all"
                    titleClass="text-white"
                    buttonClass="text-light"
                    containerClass="pt-20"
                    reverseOrder={false}
                />

                <div
                    className="pt-spacing_50 pb-spacing_100 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
                    {categoriesData.slice(0, 4).map((category, index) => (
                        <CategoryCard key={index} category={category}/>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Categories;
