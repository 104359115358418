const SportData = [
  { name: "Football",
    title: 'games',
    games: 13,
    img: require("../assets/images/Football.png"),
  },
  { name: "Tennis",
    title: 'games',
    games: 8,
    img: require("../assets/images/Tennis.png"),
  },
  { name: "Basketball",
    title: 'games',
    games: 5,
    img: require("../assets/images/Basketball.png"),
  },
  { name: "Fitness",
    title: 'Program',
    games: 3,
    img: require("../assets/images/Fitness.png"),
  },
  { name: "Baseball",
    games: 7
  },
];

export default SportData;
