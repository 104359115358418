function CorporateInfo() {
    return (
        <div className="space-y-[16px] text-white">
            <p className="text-lg font-bold">Corporate Info</p>
            <p>Home</p>
            <p>Mems</p>
            <p>Contact Us</p>
            <p>About Us</p>
        </div>
    );
}


export default CorporateInfo;