import React from "react";

function HeaderCard({
  title,
  buttonText,
  titleClass = "text-primary",
  buttonClass = "dark",
  containerClass = "pt-20",
  reverseOrder = false,
}) {
  return (
    <div className={`flex items-center ${containerClass} justify-between`}>

      {reverseOrder ? (
        <>
          <button className={`text-size-3_2 ${buttonClass}`}>{buttonText}</button>
          <h2 className={`text-size-3_2 font-bold ${titleClass}`}>{title}</h2>
        </>
      ) : (
        <>
          <h2 className={`text-size-3_2 font-bold ${titleClass}`}>{title}</h2>
          <button className={`text-size-3_2 ${buttonClass}`}>{buttonText}</button>
        </>
      )}
    </div>
  );
}

export default HeaderCard;
