function FaceBookIcon() {
    return (
        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.575 8.675H4.175V8.075V5.5C4.175 4.4325 4.59906 3.40873 5.3539 2.6539C6.10873 1.89906 7.1325 1.475 8.2 1.475H10.425V4.125H8.2C8.01943 4.125 7.84063 4.16057 7.67381 4.22967C7.50699 4.29877 7.35541 4.40005 7.22773 4.52773C7.10005 4.65541 6.99877 4.80699 6.92967 4.97381C6.86057 5.14063 6.825 5.31943 6.825 5.5V8.075V8.675H7.425H10.2915L9.62903 11.325H7.425H6.825V11.925V18.525H4.175V11.925V11.325H3.575H1.475V8.675H3.575ZM9.55791 11.6095C9.55795 11.6093 9.55798 11.6092 9.55801 11.6091L9.55791 11.6095L9.922 11.7005L9.55791 11.6095ZM10.14 11.755L10.99 8.355L7.425 5.5C7.425 5.39823 7.44505 5.29745 7.48399 5.20342C7.52294 5.10939 7.58003 5.02396 7.65199 4.95199C7.72396 4.88003 7.80939 4.82294 7.90342 4.78399C7.99745 4.74505 8.09823 4.725 8.2 4.725H10.8C10.924 4.725 11.025 4.625 11.025 4.5V1.1C11.025 0.976 10.925 0.875 10.8 0.875H8.2C6.97337 0.875 5.79699 1.36228 4.92963 2.22963C4.06228 3.09699 3.575 4.27337 3.575 5.5V7.475V8.075H2.975H1.1C0.976 8.075 0.875 8.175 0.875 8.3V11.7C0.875 11.824 0.975 11.925 1.1 11.925H2.975H3.575V12.525V18.9C3.575 19.024 3.675 19.125 3.8 19.125H7.2C7.324 19.125 7.425 19.025 7.425 18.9V12.525V11.925H8.025H9.922C10.025 11.925 10.115 11.855 10.14 11.755Z"
                stroke="#FEFEFE" strokeWidth="1.2"/>
        </svg>

    )
}


export  default FaceBookIcon;