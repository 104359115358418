function CustomerServices() {
    return (
        <div className="space-y-[16px] text-white">
            <p className="text-lg font-bold">Customer Services</p>
            <p>FAQ</p>
            <p>Shipping and Payment</p>
            <p>Return & Refund</p>
            <p>Track Order</p>
        </div>
    );
}


export default CustomerServices;