function MemCard({ meme }) {
    return (
        <div className="flex-shrink-0 bg-custom-gradient rounded-lg ">
            <div
                className="rounded-t-lg w-[383px]  h-[502px]"
                style={{
                    backgroundImage: `url(${meme.imageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            ></div>

            <p className="py-6 text-center text-white">
                {meme.title}
            </p>
        </div>
    );
}

export default MemCard;
