function TwitterIcon() {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.1168 7.62171L16.445 0H14.9452L9.45181 6.61757L5.06213 0H0L6.63701 10.008L0 18H1.49989L7.30196 11.0109L11.9379 18H17L10.1168 7.62171ZM8.06316 10.0954L7.39071 9.099L2.03985 1.17H4.34343L8.66061 7.569L9.33306 8.56543L14.9464 16.884H12.6428L8.06316 10.0954Z"
                fill="#FEFEFE"/>
        </svg>


    )
}


export default TwitterIcon