import ContactInfo from '../components/footer/ContactInfo';
import CorporateInfo from '../components/footer/CorporateInfo';
import CustomerServices from '../components/footer/CustomerServices';
import LegalInfo from '../components/footer/LegalInfo';
import SocialMedia from '../components/footer/SocialMedia';

function Footer() {
    return (
        <div className="p-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-[5%] font-poppins">
            <ContactInfo />
            <CorporateInfo />
            <CustomerServices />
            <LegalInfo />
            <SocialMedia />
        </div>
    );
}

export default Footer;
