import contactUsImage from '../assets/images/ContactUs.jpg';

function ContactUs() {
  return (
      <div className="font-poppins p-spacing_100">
        <div className="grid grid-cols-1 md:grid-cols-2 space-x-spacing_100">
          <div>
            <img
                src={contactUsImage}
                alt="Contact Us Logo"
                className="h-full w-full object-cover rounded-3xl"
            />
          </div>

          <div className="flex flex-col justify-center py-spacing_50">
            <h1 className="text-4xl text-white font-bold">Contact Us</h1>
            <p className="text-lg text-white py-spacing_22">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <form action="/submit" method="POST" className="space-y-4">
              <div className='py-4'>
                <input
                    type="text"
                    id="name"
                    name="name"
                    required
                    className="w-full p-3 bg-primary border rounded-lg text-white placeholder-gray-400"
                    placeholder="Name"
                />
              </div>
              <div className='pb-4'>
                <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    className="w-full p-3 bg-primary border rounded-lg text-white placeholder-gray-400"
                    placeholder="Email Address"
                />
              </div>
              <div className='pb-4'>
                <input
                    type="text"
                    id="message"
                    name="message"
                    required
                    className="w-full p-3 bg-primary border rounded-lg text-white placeholder-gray-400"
                    placeholder="Message"
                />
              </div>
              <div className="pt-7">
                <button
                    type="submit"
                    className="w-full p-3 bg-login-button-send text-white rounded-lg"
                >
                  Send
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>
  );
}

export default ContactUs;
