import HeaderCard from "./сards/HeaderCard";
import CryptoNewsCard from "./сards/CryptoNewsCard";

function CryptoNews({ newsData }) {
    return (
        <div className="font-poppins">
            <div className="px-spacing_100">
                <HeaderCard
                    title="Crypto News"
                    buttonText="See all"
                    titleClass="text-white"
                    buttonClass="text-light"
                    containerClass="pt-20"
                />

                <div
                    className="pt-spacing_50 pb-spacing_100 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                    {newsData.slice(0, 3).map((news, index) => (
                        <CryptoNewsCard key={index} news={news}/>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CryptoNews;
