import { useState } from "react";
import BurgerMenu from "./BurgerMenu";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="w-full pt-spacing_50 px-spacing_100 font-poppins">
      <div className="px-spacing_22 flex justify-between items-center bg-custom-gradient rounded-lg h-[80px]"  >
        <div className="flex items-center space-x-6">
          <BurgerMenu onClick={toggleMenu} />
          <div className="text-white text-2xl">Logo</div>
        </div>

        {isMenuOpen && (
          <div className="space-x-4 text-white hidden md:flex items-center">
            <a href="#">Home</a>
            <a href="#">Sport</a>
            <a href="#">Crypto</a>
            <a href="#">Trading</a>
            <a href="#">Mems</a>
            <a href="#">Trainings</a>
            <a href="#">About Us</a>
            <a href="#">Contact Us</a>
            <div className="flex items-center">
            <button className="rounded-lg text-white bg-login-button-send py-2 px-spacing_22 ml-spacing_30">
              Sign in
            </button>
            <button className="rounded-lg border border-login-button-send text-white  py-2 px-spacing_22 ml-3 mr-spacing_22">
              Sign up
            </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
