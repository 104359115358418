import HeaderCard from "./сards/HeaderCard";

function TradingAnalysis() {
    return (
        <div className="px-spacing_100 pb-spacing_100">
            <HeaderCard
                title="Trading Analysis"
                buttonText="See all"
                titleClass="text-white"
                buttonClass="text-light"
                containerClass="pt-20"
            />

            <div className="pt-spacing_50">
                <div className="bg-[#b3b3b3] rounded-lg w-full h-[300px] md:h-[500px]"></div>
            </div>
        </div>
    );
}

export default TradingAnalysis;
