import MemCard from "./сards/MemCard";

function MemeList({ memes }) {
    return (
        <div className="flex space-x-5 ">
            {memes.map((meme) => (
                <MemCard key={meme.id} meme={meme} />
            ))}
        </div>
    );
}

export default MemeList;
