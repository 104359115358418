import Sport from "../components/Sport";
import SportInfo from "../components/SportInfo";
import Header from "../layouts/Header";
import SportData from "../components/SportData";
import CryptoNews from "../components/CryptoNews";
import CryptoNewsData from "../components/CryptoNewsData";
import MemeCollections from "../components/MemeColections";
import MemeCollectionsData from "../components/MemeColectionsData";
import TradingAnalysis from "../components/TradingAnalysis";
import AboutUs from "../layouts/AboutUs";
import Categories from "../components/Categories";
import CategoriesData from "../components/CategoriesData";
import VideoCourses from "../components/VideoCourses";
import coursesData from "../components/coursesData";
import ContactUs from "../layouts/ContactUs";
import Footer from "../layouts/Footer";

import './HomePage.css';

const HomePage = () => {
    return (
        <div className="relative max-w-[1920px] mx-auto bg-primary">
            <div className="bg-starTop">
                <Header/>
                <SportInfo/>
                <Categories categoriesData={CategoriesData}/>
            <Sport sportsData={SportData}/>
            <CryptoNews newsData={CryptoNewsData}/>
                <MemeCollections newsData={MemeCollectionsData}/>
            </div>
            <TradingAnalysis/>
            <div className='bg-starCenter'>
                <AboutUs/>
                <VideoCourses coursesData={coursesData}/>
            </div>
            <div className='bg-starBottom'>
                <ContactUs/>
                <Footer/>
            </div>
        </div>
    );
};

export default HomePage;


